









































import {Component, Watch} from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import SortingModel from "@/store/entities/sortingmodel";
import GetEntityChangeInput from "../../../../store/entities/auditingcontext/getentitychangeinput";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import JobAuditingDetails from "@/views/setting/jobcontext/jobauditing/jobauditingdetails.vue";

@Component({
    components: {
        JobAuditingDetails
    }
})
export default class JobAuditing extends AbpBase {
    private users: any;

    @Watch('dateRange')
    onDateRange(val, oldVal) {
        if (val !== null && val.length > 1) {
            this.pageRequest.startDate = val[0];
            this.pageRequest.endDate = val[1];
        }
    }

    private detailsModalShow: boolean = false;

    getYearBefore() {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 1)
        return date.toISOString().slice(0, 10)
    }

    getDayAfter() {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        return tomorrow.toISOString().slice(0, 10)
    }

    async isOwnerTextChanged(val: string) {
        if (val != null && val.length >= 1) {
            await this.getUsers(val);
        }
    }

    async getUsers(query) {
        this.users = await this.$store.dispatch('user/lookUp', new KeyWordLookUpRequest(query, -1));
    }

    pageRequest: GetEntityChangeInput = new GetEntityChangeInput();
    private dateRange = [];

    async changeSort(data) {
        if (this.pageRequest.sorting == null) {
            this.pageRequest.sorting = [];
        }
        let item = this.pageRequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pageRequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getpage()
    }

    get list() {
        return this.$store.state.auditing.list;
    };


    get loading() {
        return this.$store.state.auditing.loading;
    }

    async pageChange(page: number) {
        this.$store.commit('auditing/setCurrentPage', page);
        await this.getpage();
    }

    async search() {
        await this.pageChange(1);
    }

    async pagesizeChange(pageSize: number) {
        this.$store.commit('auditing/setPageSize', pageSize);
        await this.getpage();
    }

    async getpage() {

        this.pageRequest.maxResultCount = this.pageSize;
        this.pageRequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'auditing/getAll',
            data: this.pageRequest
        })
    }

    data() {
        return {
            users: []
        }
    };

    get pageSize() {
        return this.$store.state.auditing.pageSize;
    }

    get totalCount() {
        return this.$store.state.auditing.totalCount;
    }

    get currentPage() {
        return this.$store.state.auditing.currentPage;
    }

    columns = [{
        title: this.L('Change time'),
        key: 'changeTime'
    }, {
        title: this.L('Change type'),
        key: 'changeTypeName'
    }, {
        title: this.L('User'),
        key: 'userName'
    }, {
        title: this.L('Properties'),
        key: 'properties',
        width: '400px'
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 100,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small'
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: async () => {
                            await this.details(params.row.id);
                        }
                    }
                }, this.L('Details'))
            ])
        }
    }]

    async details(id) {
        await this.$store.dispatch('auditing/getDetails', id);
        this.detailsModalShow = true;
    }

    async created() {
        this.dateRange = [this.getYearBefore(), this.getDayAfter()];
        this.pageRequest.entityId = this.$route.params["jobId"];
        this.pageRequest.entityTypeFullName = 'FFWD.LookUp.JobContext.Job.Job';
        await this.getpage();
    }
}
